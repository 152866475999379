.order-status-new {
  margin-top: 32px;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order-status-new__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-status-new__content-title {
  all: unset;
}

.order-status-new__content-body-message {
  color: var(--smoked-70);
}

.order-status-new__content-body-message--bold {
  color: var(--black);
}
